<template>
  <v-row class="login ma-0">
    <v-col>
      <v-card v-if="card == 'login'" elevation="5" max-width="1070" class="mx-auto d-flex">
        <v-row no-gutters class="py-lg-9 py-md-9">
          <v-col cols="12" md="6" class="pa-lg-15 pa-md-10 pt-10 ma-auto d-sm-block d-none">
            <v-img class="rounded-l ma-auto img" cover src="@/assets/img/auth.svg"></v-img>
          </v-col>
          <v-col cols="12" md="6" class="pa-lg-15 pa-sm-16 px-5 py-15 pt-sm-4 d-flex flex-column justify-center">
            <v-card-title class="pa-0 d-flex flex-column">
              <div class="font-weight-bold text-h4 secondary--text text-uppercase">Welcome back</div>
              <div class="f18 mb-4 mt-3 opasity--text text-center font-weight-regular">Sign-in to begin</div>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="login" ref="form" lazy-validation>
                <v-text-field
                  v-model="email"
                  label="Email"
                  :error-messages="emailErrors"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <v-text-field
                  class="mt-n1"
                  v-model="password"
                  label="Password"
                  :error-messages="passwordErrors"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <div class="text-right">
                  <router-link class="input--text" to="/password-recovery">Forgot your password?</router-link>
                </div>
                <div class="text-center mt-6">
                  <v-btn elevation="0" type="submit" max-width="300" width="100%" height="48" color="primary">Sign In</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="5" max-width="570" class="mx-auto pa-sm-15 pa-5" v-if="card == 'OTP'">
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/otp.svg" alt="lock" />
          <div class="text-h5 mt-7">Additional security measure</div>
          <v-row>
            <v-col cols="12" sm="9" class="mx-auto">
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">Please enter OTP from your 2FA application/device.</div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form @submit.prevent="otp" class="px-sm-15 px-0" ref="otp" lazy-validation>
            <v-text-field
              v-model="code"
              class="otp-input mx-1"
              :error-messages="codeErrors"
              filled
              outlined
              dense
              required
              color="input"
              maxlength="6"
            ></v-text-field>
            <div class="d-flex align-center flex-column">
              <v-btn type="submit" max-width="300" width="100%" large color="primary">Sign In</v-btn>
              <div class="mt-3">
                <span class="input--text link" @click="card = 'code'">Can`t get OTP?</span>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <div class="mt-7">
          <v-btn @click="card = 'login'" text class="text-capitalize opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </div>
      </v-card>
      <v-card elevation="5" max-width="570" class="mx-auto pa-sm-15 pa-5" v-if="card == 'code'">
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/otp.svg" alt="lock" />
          <v-row>
            <v-col cols="12" sm="9" class="mx-auto">
              <div class="text-h5 mt-7 text-break text-center">Please enter the Recovery code to complete sign-in.</div>
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">
                Please note that each recovery code can be used only once.
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form @submit.prevent="otpRecovery" class="px-sm-15 px-0" ref="otp" lazy-validation>
            <v-text-field
              v-model="codeR"
              class="mx-1"
              :error-messages="codeRErrors"
              placeholder="Enter recovery code"
              filled
              outlined
              dense
              required
              color="input"
            ></v-text-field>
            <div class="d-flex align-center flex-column">
              <v-btn type="submit" max-width="300" width="100%" large color="primary">Sign In</v-btn>
              <div class="mt-3">
                <span class="input--text link" @click="card = 'code'">Lost recovery code?</span>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <div class="mt-7">
          <v-btn @click="card = 'login'" text class="text-capitalize opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import jwt_decode from 'jwt-decode';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    email: { required, email },
    password: { required },
    code: { required },
    codeR: { required },
  },
  data() {
    return {
      card: 'login',
      email: null,
      show: false,
      password: null,
      code: null,
      codeR: null,
      status: null,
      error: null,
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      !this.$v.email.email && errors.push('Provided email address is not valid');
      !this.$v.email.required && errors.push('Please enter your email address');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      !this.$v.password.required && errors.push('Please enter your password');
      return errors;
    },
    codeRErrors() {
      const errors = [];
      if (!this.$v.codeR.$dirty) {
        return errors;
      }
      !this.$v.codeR.required && errors.push('Please enter one of OTP recovery codes');
      this.error == 'recovery_code__invalid' &&
        errors.push(
          'Provided recovery code is not correct or has been used before. Please check recovery code or use another recovery code'
        );
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) {
        return errors;
      }
      !this.$v.code.required && errors.push('Please enter OTP from your TOTP application or device');
      this.error == 'code__invalid' && errors.push('Provided OTP is not correct. Please check and try again');
      this.error == 'otp__wrong' && errors.push('Provided OTP is not correct. Please check and try again');
      return errors;
    },
  },
  methods: {
    notifi() {
      if (this.error == 'credential__invalid') {
        this.$notify({
          message: 'Provided email or password is not correct. Please check and try again',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'account__suspended') {
        this.$notify({
          message: 'Sorry, but your account has been suspended',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async login() {
      this.$v.email.$touch() || this.$v.password.$touch();
      if (!this.$v.password.$invalid || !this.$v.email.$invalid) {
        this.error = null;
        await this.$store
          .dispatch('signIn', { login: this.email, pass: this.password })
          .then((response) => {
            if (response.data.auth_token) {
              sessionStorage.setItem('jwt_token', response.data.auth_token);
              this.card = 'OTP';
            } else if (response.data.access_token) {
              if (jwt_decode(response.data.access_token).ecr) {
                this.$router.push(`/failed-confirmed?t=${response.data.access_token}`);
              } else {
                this.done(response);
              }
            }
          })
          .catch((e) => {
            this.loginError(e);
          });
      }
    },
    async otp() {
      this.$v.code.$touch();
      if (!this.$v.code.$invalid) {
        this.error = null;
        await this.$store
          .dispatch('otp', this.code)
          .then((response) => {
            this.done(response);
          })
          .catch((e) => {
            this.loginError(e);
          });
      }
    },
    async otpRecovery() {
      this.$v.codeR.$touch();
      if (!this.$v.codeR.$invalid) {
        this.error = null;
        await this.$store
          .dispatch('otpRecovery', this.codeR)
          .then((response) => {
            this.done(response);
          })
          .catch((e) => {
            this.loginError(e);
          });
      }
    },
    done(response) {
      this.status = response.status;
      sessionStorage.setItem('jwt_token', response.data.access_token);
      setTimeout(() => {
        this.$router.replace(this.$route.query.from || '/dashboard');
      }, 100);
    },
    loginError(e) {
      this.status = e.response.status;
      this.error = e.response.data.error[0];
      this.notifi();
    },
  },
};
</script>

<style>
.login .img {
  max-width: 490px;
}
@media screen and (max-width: 959px) {
  .login .img {
    max-width: 270px;
  }
}
</style>
